<template>
  <div class="header-page-grey">
    <div class="main-container gutter">
      <page-title :label="label" :name="name"/>
      <div v-if="image" class="image">
        <img :src="image" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../page-title/PageTitle.vue';

export default {
  name: 'header-page-grey',
  components: {
    PageTitle,
  },
  props: {
    name: {
      type: String,
      default: 'Titulo',
    },
    label: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" src="./HeaderPageGrey.scss" />
