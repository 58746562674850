<template>
  <section class="w-full block-slider">
    <div class="main-container">

      <block-title :label="label" :name="title" />

      <div class="btns">
        <!-- <btn class="catalogo" append-icon="file-pdf" invert-color tx="Descargar Catalogo" is-link-external link="https://issuu.com/sidanez/docs/catalogo-sidanezherrajes-2016" /> -->
        <btn v-if="showBtnCatalogo" class="catalogo" append-icon="file-pdf" invert-color tx="Descargar Catalogo" is-link-external link="https://cms.s-h.com.ar/uploads/catalogos/catalogo.pdf" />
        <btn v-if="sectionPath !== ''" tx="Ver Todos" :link="sectionPath"/>
      </div>

      <Slider :sliderItems="sliderItems" :disabled="true"
        v-if="showSlider" key="slider" :section-path="sectionPath"
        :is-product="isProduct" />

    </div>
  </section>
</template>

<script>
import Slider from '../slider/Slider.vue';
// import RepositoryFactory from '../../repositories/RepositoryFactory';

export default {
  name: 'block-slider',
  components: {
    Slider,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    sliderItems: {
      type: Array,
      required: true,
    },
    sectionPath: {
      type: String,
      default: '',
    },
    isProduct: {
      type: Boolean,
      default: true,
    },
    showBtnCatalogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },
  methods: {
    onClick() {
      this.$router.push({ path: this.sectionPath });
    },
  },
  computed: {
    showSlider() {
      return this.sliderItems && this.sliderItems.length > 0;
    },
  },
};
</script>

<style lang="scss" src="./BlockSlider.scss"/>
