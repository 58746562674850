import Vue from 'vue';
import Icon from 'vue-awesome/components/Icon.vue';
import VueGlide from 'vue-glide-js';
import 'vue-glide-js/dist/vue-glide.css';
import VueTruncate from 'vue-truncate-filter';
import * as VueGoogleMaps from 'vue2-google-maps';
import VeeValidate from 'vee-validate';
import vSelect from 'vue-select';
import Multiselect from 'vue-multiselect';
import VueScrollTo from 'vue-scrollto';
import VueMeta from 'vue-meta';
import VueGtag from 'vue-gtag';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

// Global components
import BlockTitle from './components/block-title/BlockTitle.vue';
import PageTitle from './components/page-title/PageTitle.vue';
import HeaderPageGrey from './components/header-page-grey/HeaderPageGrey.vue';
import LoadingIndicator from './components/loading-indicator/LoadingIndicator.vue';
import Btn from './components/btn/Btn.vue';

import 'vue-awesome/icons/regular/times-circle';
import 'vue-awesome/icons/at';
import 'vue-awesome/icons/phone';
import 'vue-awesome/icons/envelope';
import 'vue-awesome/icons/file-upload';
import 'vue-awesome/icons/file-pdf';
import 'vue-awesome/icons/arrow-left';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-select/dist/vue-select.css';

Vue.component('v-icon', Icon);

Vue.component('block-title', BlockTitle);
Vue.component('page-title', PageTitle);
Vue.component('header-page-grey', HeaderPageGrey);
Vue.component('loading-indicator', LoadingIndicator);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('btn', Btn);

Vue.use(VueGlide);

Vue.use(VueScrollTo);

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('multiselect', Multiselect);

Vue.component('v-select', vSelect);

Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyA5TQTtGF1s6pqEqDa8TI4XSir-uHJU9Ig',
    key: 'AIzaSyBvKK8x0ep08YkQDqCRtQnOL1_zlQJARlI', // cuenta claudio
    // key: 'AIzaSyDRJ9hEvUQVhm_CAtrdu6Tt_1LNHSoVGmc', // cuenta sh
    libraries: 'places',
  },
});

Vue.use(VueGtag, {
  config: { id: 'G-TH63K3KHTJ' },
}, router);

Vue.use(VueMeta);

Vue.config.productionTip = false;

Vue.filter('resolveImagePath', (image) => ((image && image.indexOf('http') !== 0)
  ? `${process.env.VUE_APP_UPLOAD_FOLDER}${image}`
  : image));

Vue.use(VueTruncate);

const translateField = (field) => {
  switch (field) {
    case 'name':
      return 'Nombre y Apellido';
    case 'lastname':
      return 'Apellido';
    case 'phone':
      return 'Teléfono';
    case 'zipcode':
      return 'Codigo Postal';
    default:
      return '';
  }
};

const dictionary = {
  en: {
    messages: {
      required: (field) => `El campo ${translateField(field)} es requerido.`,
      email: 'El email es invalido.',
    },
  },
};

Vue.use(VeeValidate, {
  inject: true,
  dictionary,
});

export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
