<template>
  <section class="antibacterial">
    <block-title label="Protector" name="Antibacterial" />

    <div class="antibacterial-content">

      <div class="antibacterial-text">

        <p>El nuevo recubrimiento protector antibacterial <strong>SIDAÑEZ ASEPTIC®</strong> elimina el 99.5% de los microorganismos patógenos que suelen contaminar las manijas. <strong>Sidañez Herrajes</strong>, el primer fabricante Argentino en ofrecer herrajes con tratamiento antibacterial aseptic®, proteja la salud de su familia instalando productos <strong>SIDAÑEZ ASEPTIC®</strong> en su hogar.</p>

        <p><strong>¿Por que utilizar SIDAÑEZ ASEPTIC®? </strong></p>

        <p>Por que las manijas, manijones y pomos son uno de los principales elementos de contagio de su casa al contaminarse con diversos microorganismos patógenos.</p>

        <!--
        <p>
          El nuevo tratamiento antibacteriano Fast Plus, aplicado de serie sobre toda la gama de dispositivos antipánico Fast y accionamientos externos. Este tratamiento que las puertas estén protegidas contra la difusión de mohos y bacterias.
        </p>

        <p>
          La protección se aplica mediante un tratamiento en polvo antibacteriano que impide la propagación de microrganismos peligrosos y el desarrollo de moho y bacterias. El tratamiento se ha ensayado con éxito sobre una cepa de la bacteria más común: Staphilococcus aureus.
        </p>

         <p>
          Aplicado de serie sobre toda la gama de dispositivos antipánico Fast y accionamientos externos. Este tratamiento que las puertas estén protegidas contra la difusión…
        </p> -->

        <router-link to="aseptic">Seguir Leyendo</router-link>
      </div>

      <div class="antibacterial-img">
        <img src="/img/img-aseptic-home.png" alt="">
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'antibacterial',
};
</script>

<style lang="scss" src="./Antibacterial.scss" />
