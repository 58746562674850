<template>
  <section class="news-module">
      <block-title label="Ultimas" name="Noticias" />

      <div class="news-articles" v-if="!isLoading">

        <!-- news 1 -->
        <div class="article" v-if="news.length>0">
          <div class="image-container">
            <img :src="news[0].image | resolveImagePath" alt="">
          </div>

          <h4 class="news-title">{{news[0].name}}</h4>

          <p>{{news[0].description}}</p>

          <router-link :to="`news/${news[0].link}`">Seguir Leyendo</router-link>
        </div>

        <!-- news 2 -->
        <div class="article" v-if="news.length>1">
          <div class="image-container">
            <img :src="news[1].image | resolveImagePath" alt="">

          </div>

          <h4 class="news-title">{{news[1].name}}</h4>

          <p>{{news[1].name}}</p>

          <router-link :to="`news/${news[1].link}`">Seguir Leyendo</router-link>
        </div>

      </div>

  </section>
</template>

<script>
import RepositoryFactory from '../../../repositories/RepositoryFactory';

const NewsRespository = RepositoryFactory.get('news');

export default {
  name: 'news-module',
  data() {
    return {
      isLoading: false,
      news: [],

    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      const { data } = await NewsRespository.get(2);

      this.news = data.data;
      this.isLoading = false;
    },
  },
};
</script>

<style src="./NewsModule.scss" lang="scss" />
