import Repository from './Repository';

const resource = '/articles';
export default {
  get(limit) {
    let url = `${resource}?p=y`;
    if (limit) url = `${resource}?perPage=${limit}&p=y`;
    return Repository.get(url);
  },
  getNews(newsId) {
    return Repository.get(`${resource}/${newsId}`);
  },
};
