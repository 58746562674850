<template>
  <div class="block-title">
    <h5>{{label}}</h5>
    <h2>{{name}}</h2>
  </div>
</template>

<script>
export default {
  name: 'block-title',
  props: {
    name: {
      type: String,
      default: 'Titulo',
    },
    label: {
      type: String,
      default: 'Coleccion',
    },
  },
};
</script>

<style lang="scss">
.block-title {
  h5 {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
    color: $main-color;
    font-weight: 300;
    font-size: .65rem;
  }
  h2 {
    margin: 2px 0 0 -2px;
    color: $main-color;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1;
  }

  @media (min-width: $screen-md) {

    h5 {
      letter-spacing: 2px;
    }

    h2 {
      margin: 1px 0 0 -3px;
      font-size: 2.25rem;
    }
  }
}
</style>
