import axios from 'axios';

const baseDomain = process.env.VUE_APP_CMS_URL || 'https://cms.s-h.com.ar';
const baseURL = `${baseDomain}/api`;

const Repository = axios.create({
  baseURL,
});

export default Repository;
