import Vue from 'vue';
import Vuex from 'vuex';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';

Vue.use(Vuex);

const state = {
  menuIsOpen: false,
  searchIsOpen: false,
  categories: null,
  collections: null,
  highlights: null,
  finishings: null,
  materials: null,
  contactMessage: null,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
