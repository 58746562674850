import Vue from 'vue';
import VueRouter from 'vue-router';

import EventBus from '@/eventBus';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition;
  }
  const position = {};

  // scroll to anchor by returning the selector
  if (to.hash) {
    position.selector = to.hash;
    // specify offset of the element
    if (to.hash === '#anchor2') {
      position.offset = {
        y: 100,
      };
    }

    if (document.querySelector(to.hash)) {
      return position;
    }
  }

  return new Promise((resolve) => {
    if (to.matched.some((m) => m.meta.scrollToTop) && !to.hash) {
      position.x = 0;
      position.y = 0;
    }

    EventBus.$once('triggerScroll', () => {
      setTimeout(() => {
        resolve(position);
      }, 100);
    });
  });
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      scrollToTop: true,
    },
  },
  {
    path: '/products',
    name: 'categories',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "categories" */ '../views/Categories.vue'),
  },

  {
    path: '/collections',
    name: 'collections',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "collections" */ '../views/Collections.vue'),
  },

  {
    path: '/collections/:id',
    name: 'product-by-collection',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "productscollections" */ '../views/ProductsByCollection.vue'),
  },

  {
    path: '/products/:id',
    name: 'products',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue'),
  },

  {
    path: '/products/:type/:id',
    name: 'product-detail',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "productdetail" */ '../views/ProductDetail.vue'),
  },

  {
    path: '/collections/:collection/:id',
    name: 'product-collection-detail',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "productdetail" */ '../views/ProductDetail.vue'),
  },

  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news-list" */ '../views/News.vue'),
  },

  {
    path: '/news/:link',
    name: 'news-detail',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsDetail.vue'),
    props: true,
  },

  {
    path: '/company',
    name: 'company',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "company" */ '../views/Company.vue'),
  },

  {
    path: '/contact',
    name: 'contact',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },

  {
    path: '/trabaja-con-nosotros',
    name: 'work-with-us',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "workwithus" */ '../views/WorkWithUs.vue'),
  },

  {
    path: '/profesionales',
    name: 'profesionales',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "profesionales" */ '../views/Profesionales.vue'),
  },

  {
    path: '/instructivos',
    name: 'instructivos',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "instructivos" */ '../views/Instructivos.vue'),
  },

  {
    path: '/acabados',
    name: 'acabados',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "acabados" */ '../views/Acabados.vue'),
  },

  {
    path: '/aseptic',
    name: 'aseptic',
    meta: {
      scrollToTop: true,
    },
    component: () => import(/* webpackChunkName: "aseptic" */ '../views/Aseptic.vue'),
  },

  // otherwise redirect to home
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes,
});

export default router;
