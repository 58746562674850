import Repository from './Repository';

const resource = '/categories';
export default {
  get(limit) {
    let url = `${resource}`;
    if (limit) url = `${resource}?perPage=${limit}&p=y`;
    return Repository.get(url);
  },
  home(perPage) {
    return Repository.get(`${resource}?perPage=${perPage}&p=y`);
  },
};
