<template>
  <header class="header-site" :class="{'dark-mode' : $store.state.menuIsOpen}">
    <div class="gutter header-content">

      <router-link class="logo" to="/">
        <img class="logo-color" src="./../../assets/logo-color.svg" alt="Sidañez Herrajes">
        <img class="logo-white" src="./../../assets/logo-white.svg" alt="Sidañez Herrajes">
        <p class="logo-text">Tecnología + Personas</p>
      </router-link>

      <nav>
        <ul>
          <li><router-link to="/products">Productos</router-link></li>
          <li class="hide-lg"><router-link :to="{ name: 'collections'}">Colecciones</router-link></li>
          <li><router-link to="/acabados">Acabados</router-link></li>
          <!-- <li class="hide-md"><router-link to="/company">Empresa</router-link></li> -->
          <li><router-link to="/contact">Contacto</router-link></li>
          <li><router-link to="/profesionales">Profesionales</router-link></li>
          <li><router-link to="/instructivos">Instructivos</router-link></li>
        </ul>
      </nav>

      <a href="#" @click.prevent="toggleSearch" class="search-btn" :class="{'is-active' : this.$store.state.searchIsOpen}">
        <img class="search" src="../../assets/search-icon.svg" alt="">
        <img class="close" src="../../assets/close.svg" alt="">
      </a>

      <a href="#" @click.prevent="toggleMenu" class="btn-menu-mobile"
        :class="{'is-active' : this.$store.state.menuIsOpen}" >
        <img src="../../assets/icon-menu-mobile.svg" alt="">
      </a>

      <ul class="share-options">
        <!-- <li><a href="#"><v-icon name="brands/facebook-f" label="facebook"/></a></li> -->
        <li><a href="https://www.instagram.com/sidanez_herrajes/" target="blank"><v-icon name="brands/instagram" label="instagram"/></a></li>
      </ul>
    </div>
    <menu-mobile />
  </header>
</template>

<script>
import { mapActions } from 'vuex';
import MenuMobile from '../menu-mobile/MenuMobile.vue';
import 'vue-awesome/icons/brands/facebook-f';
import 'vue-awesome/icons/brands/instagram';

export default {
  name: 'HeaderSite',
  components: {
    MenuMobile,
  },
  methods: {
    ...mapActions(['openMenu', 'closeMenu', 'toggleMenu', 'toggleSearch']),
  },
};
</script>

<style lang="scss" src="./HeaderSite.scss"></style>
