<template>
  <div class="page-title">
    <span class="texts">
      <h5 v-html="label"/>
      <h2 v-html="name"/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'page-title',
  props: {
    name: {
      type: String,
      default: 'Titulo',
    },
    label: {
      type: String,
      default: 'Label',
    },
  },
};
</script>

<style lang="scss" src="./PageTitle.scss" />
