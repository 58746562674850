<template>
  <footer class="footer-site">
    <div class="footer-nav">
      <div class="main-container gutter">

        <router-link class="logo" to="/">
          <img src="./../../assets/logo-color.svg" alt="Sidañez Herrajes">
          <p class="logo-text">Tecnología + Personas</p>
        </router-link>

        <nav>
          <ul>
            <li><router-link :to="{ name: 'categories' }">Productos</router-link></li>
            <li><router-link :to="{ name: 'collections'}">Colecciones</router-link></li>
            <li><router-link to="/acabados">Acabados</router-link></li>
            <li><router-link to="/contact">Contacto</router-link></li>
            <!-- <li><router-link to="/company">Empresa</router-link></li> -->
            <li><router-link to="/profesionales">Profesionales</router-link></li>
            <li><router-link to="/instructivos">Instructivos</router-link></li>
            <li><router-link to="/aseptic">Aseptic</router-link></li>
            <li><a href="https://cms.s-h.com.ar/uploads/catalogos/catalogo.pdf" target="blank">Catálogo</a></li>
          </ul>
        </nav>

        <ul class="share-options">
          <!-- <li><a href="#"><v-icon name="brands/facebook-f" label="facebook"/></a></li> -->
          <li><a href="https://www.instagram.com/sidanez_herrajes/"><v-icon name="brands/instagram" label="instagram"/></a></li>
        </ul>

      </div>

    </div>

    <div class="footer-copy">
      <div class="main-container gutter">
        <p>Planta Industrial y Showroom. Chiclana 121, Tablada, Bs As. Tel. (54) 11 - 4652 - 9760 / 2627 / 0319</p>
        <p>2023 Sidañez Herrajes. Todos los derechos reservados.</p>
      </div>
    </div>
  </footer>

</template>

<script>

export default {
  name: 'footer-site',
};
</script>

<style lang="scss" src="./FooterSite.scss"/>
