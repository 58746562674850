export default {

  setCategories(state, data) {
    state.categories = data; // eslint-disable-line no-param-reassign
  },

  setCollections(state, data) {
    state.collections = data; // eslint-disable-line no-param-reassign
  },

  setHighlights(state, data) {
    state.highlights = data; // eslint-disable-line no-param-reassign
  },

  setMaterials(state, data) {
    state.materials = data; // eslint-disable-line no-param-reassign
  },

  setFinishings(state, data) {
    state.finishings = data; // eslint-disable-line no-param-reassign
  },

  setContactMessage(state, data) {
    state.contactMessage = data; // eslint-disable-line no-param-reassign
  },

  openMenu(state) {
    state.menuIsOpen = true; // eslint-disable-line no-param-reassign
  },
  closeMenu(state) {
    state.menuIsOpen = false; // eslint-disable-line no-param-reassign
  },

  openSearch(state) {
    state.searchIsOpen = true; // eslint-disable-line no-param-reassign
  },
  closeSearch(state) {
    state.searchIsOpen = false; // eslint-disable-line no-param-reassign
  },

};
