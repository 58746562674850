<template>
  <div class="btn" :class="{ invert: invertColor, blue: blue }">
    <a href="#" @click.prevent="onClickBtn">
      <span v-if="appendIcon" class="input-icon">
        <v-icon :name="appendIcon" />
      </span>
      <span>{{ tx }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'btn',
  props: {
    tx: {
      type: String,
      default: 'Ver más',
    },
    link: {
      type: String,
    },
    isLinkExternal: {
      type: Boolean,
      default: false,
    },
    invertColor: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: '',
    },
    blue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickBtn() {
      // console.log('click');
      this.$emit('onClick');
      if (this.link) {
        if (!this.isLinkExternal) {
          this.$router.push(this.link);
        } else {
          window.open(this.link, '_blank');
        }
      }
    },
  },
};
</script>

<style lang="scss" src="./Btn.scss"/>
