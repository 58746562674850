<template>
  <vue-glide ref="slider" class="slider"
    :per-view="perView"
    :breakpoints="breakpoints"
    :bound="true"
    :rewind="false"
    @glide:move-after="onAfterMove"
    @glide:move="onMove"
    @glide:swipe-start="onSwipeStart"
    @glide:swipe-end="onSwipeEnd"
    @glide:resize="onResize"
  >

    <vue-glide-slide v-for="(slide, index) in sliderItems" :key="index" class="thumb">

      <span @mouseup="onClick(index)" @click="onMobileClick(index)">
        <div class="img-container">
          <!-- <img :src="getImage(index)" :alt="slide.name"/> -->
          <div class="image-bg" :style="getBg(index)"></div>
        </div>

        <p v-html="slide.name"></p>
      </span>

    </vue-glide-slide>

  </vue-glide>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'slider',
  props: {
    sliderItems: {
      type: Array,
      default: () => [],
    },
    linkToAll: {
      type: String,
      default: null,
    },
    sectionPath: {
      type: String,
      default: '/products/',
    },
    isProduct: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      perView: 4,
      breakpoints: {
        600: { perView: 1.4 },
        1100: { perView: 3.25 },
        1200: { perView: this.perView },
      },
      slideDisabled: true,
      move: false,
      firstTime: true,
      touchmoving: false,
    };
  },
  methods: {
    onSwipeStart() {
      this.touchmoving = true;
    },
    onSwipeEnd() {
      this.touchmoving = false;
    },
    onMove() {
      this.move = true;
      if (this.firstTime) {
        this.firstTime = false;
        this.onResize();
      }
    },
    onAfterMove() {
      this.move = false;
    },
    onMobileClick(productIndex) {
      if (!this.touchmoving && window.innerWidth < 790) this.goProduct(productIndex);
    },
    onClick(productIndex) {
      if (!this.move) this.goProduct(productIndex);
    },
    onResize() {
      if (window.innerWidth < 720 || this.sliderItems.length > this.perView) {
        this.slideDisabled = false;
        this.slider.disabled = false;
      } else {
        this.slideDisabled = true;
        this.slider.disabled = true;
        // this.slider.update({ startAt: 0 });
      }
    },
    goProduct(index) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(index)) return;

      const item = this.sliderItems[index];
      const { link, name } = this.sliderItems[index];
      // console.log(link);
      console.log('category_id', item.category_id);
      console.log(`product index: ${index} ${name} ${link}`);
      // Todo get category of related product
      let path = `${this.sectionPath}${link}`;
      if (this.isProduct && item.category_id) path = `/products/${this.getCategoriesById(item.category_id).link}/${link}`;

      this.$router.push({ path });
    },
    getImage(index) {
      const imageName = this.sliderItems[index].image || this.sliderItems[index].image_default;
      return this.$options.filters.resolveImagePath(imageName);
    },
    getBg(index) {
      const imageName = this.sliderItems[index].image || this.sliderItems[index].image_default;
      return `background-image: url(${this.$options.filters.resolveImagePath(imageName)})`;
    },
  },
  computed: {
    slider() {
      return this.$refs.slider.glide;
    },
    ...mapGetters([
      'getCategoriesById',
      'getCollectionName',
    ]),
  },
};
</script>

<style lang="scss" src="./Slider.scss"/>
