<template>
  <div class="menu-mobile" :class="{'open' : $store.state.menuIsOpen}">
    <nav>
        <ul>
          <li><router-link :to="{ name: 'categories' }">Productos</router-link></li>
          <li><router-link :to="{ name: 'collections'}">Colecciones</router-link></li>
          <li><router-link to="/acabados">Acabados</router-link></li>
          <!-- <li><router-link to="/company">Empresa</router-link></li> -->
          <li><router-link to="/contact">Contacto</router-link></li>
          <li><router-link to="/profesionales">Profesionales</router-link></li>
          <li><router-link to="/trabaja-con-nosotros">Trabajá con nosotros</router-link></li>
          <li><router-link to="/instructivos">Instructivos</router-link></li>
          <li><router-link to="/aseptic">Aseptic</router-link></li>
          <li><a href="https://cms.s-h.com.ar/uploads/catalogos/catalogo.pdf" download target="_blank">Catálogo</a></li>
        </ul>
      </nav>
  </div>
</template>

<script>
// https://freefrontend.com/css-mobile-menus/
// https://dribbble.com/shots/1954664-CSS-Menu-Animations#shot-description
// https://jonsuh.com/hamburgers/

export default {
  name: 'menu-mobile',
};
</script>

<style src="./MenuMobile.scss" lang="scss"/>
