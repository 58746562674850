<template>
  <div class="highlights">

      <vue-glide
        type="carousel"
        :per-view="1"
        :bound="true"
        :rewind="false"
        :bullet="true"
        :autoplay="4000"
      >

        <vue-glide-slide
          v-for="(hl, index) in $store.state.highlights"
          :key="index">

          <div class="main-container gutter">
            <div class="hl">
              <div class="texts-hl-container">
                <h2>{{hl.name}}</h2>
                <p v-if="hl.description" v-html="hl.description"/>
                <a v-if="hl.link" :href="hl.link">
                  <span>{{ hl.txLink  || 'Ver producto' }}</span>
                </a>
              </div>

              <div class="img-hl-container">
                <img :src="hl.image | resolveImagePath" :alt="hl.name">
              </div>
            </div>
          </div>
        </vue-glide-slide>

        <template slot="control">
          <button class="slide-arrows prev" data-glide-dir="<">
            <v-icon name="chevron-left" />
          </button>
          <button class="slide-arrows next" data-glide-dir=">">
            <v-icon name="chevron-right" />
          </button>
        </template>
      </vue-glide>
  </div>
</template>

<script>
import 'vue-awesome/icons/chevron-left';
import 'vue-awesome/icons/chevron-right';

export default {
  name: 'highlights',

};
</script>

<style lang="scss" src="./Highlights.scss"></style>
