<template>
  <div class="search-container">
    <multiselect
      name="search-multiselect"
      :options="products"
      placeholder="Buscar Productos"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      v-model="searchModel"
      :limit="6"
      :optionHeight="80"
      :showLabels="false"
      :show-no-results="true"
      @search-change="find"
      @select="onSelectProduct"
      :clear-on-select="false"
      :close-on-select="true"
      :hide-selected="true"
      track-by="id" label="name"
      open-direction="bottom"
      class="search"

    >
      <template slot="option" slot-scope="props">
        <!-- <img class="option__image" :src="props.option.image_default" alt=""> -->
        <div class="image-container" >
          <div class="image" :style="getBg(props.option.image_default)"></div>
        </div>
        <div class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
          <span class="option__label">{{ props.option.category.name }}</span>
        </div>
      </template>
      <span class="no-results" slot="noResult">
        <p>
          No encontramos productos con ese nombre.
        </p>
      </span>
      <span class="no-results" slot="noOptions">-</span>
      <span class="search-icon" slot="caret">
        <img src="../../assets/search-icon.svg" alt="">
      </span>
    </multiselect>
  </div>
</template>

<script>
import RepositoryFactory from '../../repositories/RepositoryFactory';

const ProductsRespository = RepositoryFactory.get('products');

export default {
  name: 'search',
  data() {
    return {
      searchModel: null,
      isLoading: false,
      products: [],
    };
  },
  methods: {
    getBg(img) {
      return `background-image: url(${this.$options.filters.resolveImagePath(img)})`;
    },
    async find(query) {
      this.isLoading = true;

      if (query && query.length > 1) {
        const { data } = await ProductsRespository.search(query);
        this.products = data.data;
      } else {
        this.products = [];
      }

      this.isLoading = false;
    },
    onSelectProduct(product) {
      // console.log(product);
      this.$router.push(`/products/${product.category.link}/${product.link}`);
    },
  },

};
</script>

<style lang="scss" src="./Search.scss"/>
