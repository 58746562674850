<template>
  <section class="w-full subscription">
    <div class="main-container gutter">

      <block-title label="Suscríbase a" name="Nuestro Newsletter" />

      <form action="/contacts" @submit="checkForm"
        class="form-subscription" novalidate="true">

        <div class="field">
          <!-- <label form="name" class="label">Nombre y Apellido</label> -->
          <div class="control">
            <input name="name" v-model="form.name" class="input" type="text" placeholder="Ingrese su Nombre y Apellido">
          </div>
        </div>

        <div class="field">
          <!-- <label for="email" class="label">Email</label> -->
          <div class="control">
            <input name="email" v-model="form.email" class="input" type="email" placeholder="Ingrese su e-mail">
          </div>
        </div>

        <input :disabled="sending" type="submit" class="btn-send" value="Enviar"/>

      </form>

      <p class="form-errors" v-if="formErrors.length > 0">
        <b>Por favor complete o corrija el/los siguientes error/es:</b>
        <ul>
          <li v-for="(error, index) in formErrors" :key="index">{{ error }}</li>
        </ul>
      </p>

      <p class="form-ok" v-if="sendOk">
        Suscripción realizada correctamente.
      </p>

    </div>
  </section>
</template>

<script>
import axios from 'axios';

const baseDomain = process.env.VUE_APP_CMS_URL || 'http://dev.ingeniovisual.com.ar';
const serviceUrl = `${baseDomain}/api/subscription`;

export default {
  name: 'subscription',
  data() {
    return {
      formErrors: [],
      sending: false,
      sendOk: false,
      form: {
        name: '',
        email: '',
      },
    };
  },
  methods: {
    checkForm(e) {
      e.preventDefault();

      this.formErrors = [];

      if (!this.form.name) {
        this.formErrors.push('El nombre es requerido.');
      }
      if (!this.form.email) {
        this.formErrors.push('El email es requerido.');
      } else if (!this.validEmail(this.form.email)) {
        this.formErrors.push('El email es invalido.');
      }

      if (!this.formErrors.length) {
        // return true;
        this.sendData();
      }
    },
    sendData() {
      this.sending = true;
      axios.post(serviceUrl, this.form)
        .then(() => {
          // console.log(response);
          this.sendOk = true;
        })
        .catch(() => {
          // console.log(error);
          this.sending = false;
        });
    },
    validEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style lang="scss" src="./Subscription.scss"></style>
