import CategoriesRepository from './categoriesRepository';
import CollectionsRepository from './collectionsRepository';
import ProductsRepository from './productsRepository';
import NewsRepository from './newsRepository';
import HomeRepository from './homeRepository';
import FinishingsRepository from './finishingsRepository';

const repositories = {
  categories: CategoriesRepository,
  collections: CollectionsRepository,
  products: ProductsRepository,
  news: NewsRepository,
  home: HomeRepository,
  finishings: FinishingsRepository,
};

const RepositoryFactory = {
  get: (name) => repositories[name],
};

export default RepositoryFactory;
