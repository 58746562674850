<template>
  <section class="home">
    <Highlights />

    <BlockSlider label="Tipos de" title="Productos"
      :slider-items="$store.state.categories"
      section-path="products/" :is-product="false"  class="bg-grey"
      :show-btn-catalogo="true" />

    <BlockSlider label="Nuestras" title="Colecciones"
      :slider-items="$store.state.collections"
      section-path="collections/" :is-product="false"
      :show-btn-catalogo="true" />

    <section class="w-full bg-grey">

      <div class="main-container gutter flex">
        <NewsModule  />
        <Antibacterial  />
      </div>

    </section>

    <Subscription />
  </section>
</template>

<script>
import Highlights from '../components/home/highlights/Highlights.vue';
import Subscription from '../components/subscription/Subscription.vue';
import Antibacterial from '../components/home/antibacterial/Antibacterial.vue';
import NewsModule from '../components/home/news-module/NewsModule.vue';
// import SliderCategories from '../components/home/slider-categories/SliderCategories.vue';
import BlockSlider from '../components/block-slider/BlockSlider.vue';

export default {
  name: 'home',
  components: {
    // SliderCategories,
    Highlights,
    Subscription,
    Antibacterial,
    NewsModule,
    BlockSlider,
  },
};
</script>
