<template>
  <div class="back-to-top" v-scroll-to="'body'">
    <img v-if="showIcon" src="./../../assets/scroll-top-icon.svg" alt="back to top">
  </div>
</template>

<script>
export default {
  data() {
    return {
      showIcon: false,
    };
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 450) {
        this.showIcon = true;
      } else {
        this.showIcon = false;
      }
    },
    onClickScroll() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-top {
  position: fixed;
  z-index: 999;
  bottom: 60px;
  right: 20px;
  img {
    width: 60px;
    cursor: pointer;
    // opacity: .6;
  }

  @media (min-width: $screen-md) {
    img{
      opacity: .6;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
