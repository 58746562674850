const getters = {

  mainDataLoaded: (state) => state.categories !== null,

  // getInterestById(state) {
  //   return state.triptypes ? state.triptypes
  //     .find(item => item._id === 1) : '';
  // },

  getCategoriesById: (state) => (id) => state.categories.find((item) => item.id === id),

  getCollectionsById: (state) => (id) => state.collections.find((item) => item.id === id),

  getCategoryName: (state) => (id) => state.categories.find((item) => item.id === id).name,

  getCollectionName: (state) => (id) => state.collections.find((item) => item.id === id).name,

  getFinishingName: (state) => (id) => {
    const finishing = state.finishings.find((item) => item.id === id);
    if (finishing) return finishing.name;
    return '';
  },

  getMaterialName: (state) => (id) => {
    const material = state.materials.find((item) => item.id === id);
    if (material) return material.name;
    return '';
  },

  // videoIds: (state) => {
  //   if (state.videos) {
  //     return state.videos.map(item => item.youtube_id);
  //   }
  //   return [];
  // },

};

export default getters;
