import RepositoryFactory from '../repositories/RepositoryFactory';

const homeRepository = RepositoryFactory.get('home');

export default {

  // Menu actions
  openMenu: ({ commit }) => commit('openMenu'),
  closeMenu: ({ commit }) => commit('closeMenu'),
  toggleMenu({ commit, state }) {
    if (state.menuIsOpen) {
      commit('closeMenu');
    } else {
      commit('openMenu');
    }
  },

  // Search actions
  openSearch: ({ commit }) => commit('openSearch'),
  closeSearch: ({ commit }) => commit('closeSearch'),
  toggleSearch({ commit, state }) {
    if (state.searchIsOpen) {
      commit('closeSearch');
    } else {
      commit('openSearch');
    }
  },

  async getMainData({ commit }) {
    const { data } = await homeRepository.get();
    commit('setCategories', data.categories);
    commit('setCollections', data.collections);
    commit('setHighlights', data.highlights);
    commit('setFinishings', data.finishings);
    commit('setMaterials', data.materials);
  },

  changeContactMessage: ({ commit }, msg) => {
    commit('setContactMessage', msg);
  },

};
