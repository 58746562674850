<template>
  <div class="loading-indicator">

    <div class="content" v-if="show">
      <half-circle-spinner
            :animation-duration="1000"
            :size="26"
            color="#ec1d24"

          />
    </div>

  </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners';

export default {
  components: {
    HalfCircleSpinner,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
.loading-indicator {
  .content {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 40vh auto;
  }
  @media (min-width: $screen-lg) {
    .content {
      margin: 30vh auto;
    }
  }
}
</style>
