import Repository from './Repository';

const resource = '/products';
export default {
  get() {
    return Repository.get(`${resource}?p=y`);
  },
  search(query) {
    // return Repository.get(`${resource}?filter=${query}`);
    return Repository.get(`${resource}/search?filter=${query}`);
  },
  getByCategory(categoryId) {
    return Repository.get(`${resource}?category=${categoryId}&p=y`);
  },
  getByCollection(collectionId) {
    return Repository.get(`${resource}?collection=${collectionId}&p=y`);
  },
  getProduct(productId) {
    return Repository.get(`${resource}/${productId}`);
  },
};
