<template>
  <div id="app" :class="{'menu-open' : $store.state.menuIsOpen}">
    <transition name="fade">
      <Search v-if="this.$store.state.searchIsOpen"/>
    </transition>
    <BackToTop />
    <HeaderSite/>
    <loading-indicator :show="!this.$store.getters.mainDataLoaded" />
    <transition name="fade" mode="out-in" @after-leave="afterLeave">
      <router-view class="router-view" v-if="this.$store.getters.mainDataLoaded"
        :key="$route.fullPath" />
    </transition>
    <FooterSite/>
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import HeaderSite from './components/header/HeaderSite.vue';
import FooterSite from './components/footer/FooterSite.vue';
import BackToTop from './components/back-to-top/BackToTop.vue';
import Search from './components/search/Search.vue';

export default {
  metaInfo: {
    title: 'Sidañez Herrajes',
    meta: [
      { name: 'description', content: 'Sidañez Herrajes. Manijas, Manijas con chapa, Manijones, Pomos, Pomelas, Línea para Muebles.' },
      { name: 'keywords', content: 'Sidañez Herrajes. Manijas, Manijas con chapa, Manijones, Pomos, Pomelas, Línea para Muebles.' },
    ],
  },
  components: {
    HeaderSite,
    FooterSite,
    BackToTop,
    Search,
  },
  created() {
    this.$store.dispatch('getMainData');
  },
  methods: {
    afterLeave() {
      EventBus.$emit('triggerScroll');
    },
  },
  computed: {
    menuStatus() {
      return this.$store.state.menuIsOpen ? 'true' : 'false';
    },
  },
  watch: {
    $route() {
      this.$store.dispatch('closeMenu');
      this.$store.dispatch('closeSearch');
    },
  },
};
</script>

<style lang="scss" src="./App.scss"></style>
